// import config from '../config/api';

// import api from './api';

// export const getImagesApi = patent =>
//   api.get(`${config.apiQuercusUrl}?license=${patent}`);

// export const getImageDetailApi = (id, token) =>
//   api.get(`${config.apiQuercusUrl}/directions?id=${id}&token=${token}`);

// export const getFloorDataApi = (areaDesc, mallId) =>
//   api.get(
//     `${config.apiParkingUrl}?include=[%22areaPoi%22]&areaName=${areaDesc}`,
//     {
//       headers: {
//         'A-Mall-Id': mallId
//       }
//     }
//   );

// export const getMainSearchResults = () => {};

import config from '../config/api';

import api from './api';

const mallId = '1';
const language = localStorage.getItem('language') || 'es';

export const getImagesApi = patent =>
  api.get(`${config.baseApiUrlArauco}/v1/parking/spots?license=${patent}`, {
    headers: {
      'Mall-Id': mallId
    }
  });

export const getImageDetailApi = (patent, token) =>
  api.get(
    `${config.baseApiUrlArauco}/v1/parking?spotId=${patent.spotId}&spotStayId=${patent.spotStayId}&languageCode=${language}`,
    {
      headers: {
        'Mall-Id': mallId,
        Token: token
      }
    }
  );

export const getFloorDataApi = areaDesc =>
  api.get(
    `${config.apiParkingUrl}?include=[%22areaPoi%22]&areaName=${areaDesc}`,
    {
      headers: {
        'A-Mall-Id': mallId
      }
    }
  );

export const getMainSearchResults = () => {};
