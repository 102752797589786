import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getTotemData } from '../../config/kiosk';
import { DIRECTORY } from '../../constants/directories';

import NoResults from './NoResults';

class ListaCategorias extends React.PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    lastSearchInput: PropTypes.string.isRequired,
    onCategoryClick: PropTypes.func.isRequired,
    partialMatch: PropTypes.bool,
    directory: PropTypes.string.isRequired
  };

  static defaultProps = {
    partialMatch: false
  };

  handleHighlightSearch = text => {
    const { lastSearchInput } = this.props;
    let higlight;
    let rest;

    if (
      text &&
      text.toLowerCase().substring(0, lastSearchInput.length) ===
        lastSearchInput.toLowerCase()
    ) {
      higlight =
        lastSearchInput.charAt(0).toUpperCase() +
        lastSearchInput.toLowerCase().slice(1);
      rest = text.substring(higlight.length, text.length);
    }

    if (!rest) {
      return <b>{text}</b>;
    }

    return (
      <span>
        <b>
          {higlight}
          {rest}
        </b>
      </span>
    );
  };

  renderCategories = (data, onClick) =>
    data.map(category => (
      <li
        key={category.name}
        onClickCapture={onClick(category)}
        className='no-borde'
      >
        <div className='smart-section-container'>
          <div className='smart-section smart-section-icon'>
            <div className='demoIcon' /* style={{ marginTop: -5 }} */>
              <i className={category.icon} style={{ fontSize: '1.8em' }} />
            </div>
          </div>
          <div className='smart-section'>
            {this.handleHighlightSearch(category.name)}
          </div>

          <div className='smart-section smart-section-adicional-info'>
            <div className='informacion-adicional outlined-blue m-t-xs'>
              <div className='informacion-titulo'>
                {`${category.totalPois ? category.totalPois : 0} `}
                {category.totalPois === '1' ? (
                  <Trans i18nKey='smart_search_store' />
                ) : (
                  <Trans i18nKey='smart_search_stores' />
                )}
              </div>
            </div>
          </div>
        </div>
      </li>
    ));

  render() {
    const {
      data,
      lastSearchInput,
      onCategoryClick,
      partialMatch,
      directory
    } = this.props;

    const urlStore = `/${getTotemData(true).deviceName}/MainTienda`;
    const urlService = `/${getTotemData(true).deviceName}/MainServicios`;

    const title = 'smart_search_category_results_title';
    const partialMatchTitle = 'smart_search_result_title_partial';

    let textLink = null;

    if (directory === DIRECTORY.GASTRONOMY) {
      textLink = (
        <div className='text-link'>
          <Trans
            i18nKey='smart_search_no_gastronomy_results_third'
            components={[<Link to={urlStore} />, <Link to={urlService} />]}
          />
        </div>
      );
    } else if (directory === DIRECTORY.STORE) {
      textLink = (
        <div className='text-link'>
          <Trans
            i18nKey='smart_search_no_store_results_third'
            components={[<Link to={urlService} />]}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        <h4>
          <Trans i18nKey={!partialMatch ? title : partialMatchTitle} />
        </h4>
        {data && data.length ? (
          <ul className='lista-categorias'>
            {this.renderCategories(data, onCategoryClick)}
          </ul>
        ) : (
          <NoResults
            text={
              <React.Fragment>
                <Trans i18nKey='smart_search_no_category_results_first' />
                {` "${lastSearchInput}" `}
                <Trans i18nKey='smart_search_no_category_results_second' />
                {textLink}
              </React.Fragment>
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default ListaCategorias;
