import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { timeFormat, timeZone } from '../../constants/momentFormat';
import { handleOpen } from '../../helpers/customerServiceHours';
import { getTotemData } from '../../config/kiosk';
import { DIRECTORY } from '../../constants/directories';

import './tagResults.scss';

const STATE_ACTIVE = 1;
const DEFAULT_OPENING = '09:00';
const DEFAULT_CLOSING = '20:00';

moment.tz.setDefault(timeZone);

class ListaTiendas extends React.PureComponent {
  static propTypes = {
    showNoResults: PropTypes.bool,
    data: PropTypes.array.isRequired,
    lastSearchInput: PropTypes.string.isRequired,
    onStoreClick: PropTypes.func.isRequired,
    underLine: PropTypes.any,
    postUnderLine: PropTypes.bool,
    partialMatch: PropTypes.bool,
    directory: PropTypes.string.isRequired,
    suggestedTags: PropTypes.array.isRequired,
    suggestedTagsProducts: PropTypes.array.isRequired
  };

  static defaultProps = {
    showNoResults: false,
    underLine: null,
    postUnderLine: false,
    partialMatch: false
  };

  textPoi = {
    6: 'Cerrado temporalmente',
    3: 'En Remodelación',
    4: 'Próximamente'
  };

  enableShopShowing = [1, 3, 4, 6];

  renderShops = (data, clickCallback) =>
    data.map(shop => {
      const routeDisabled = this.textPoi[shop.poiStateId]
        ? 'route-disabled'
        : null;

      let categories = [];

      if (shop.categories) {
        categories =
          shop.categories.length < 3
            ? shop.categories
            : shop.categories.slice(0, 2);
      }

      if (this.enableShopShowing.includes(shop.poiStateId)) {
        return (
          <li
            onClickCapture={!routeDisabled ? clickCallback(shop) : null}
            className='no-borde shop-item'
            key={shop.name}
          >
            <img alt='logo-tienda' src={shop.logoUrl} />
            <span>
              <div className={`titulo-tienda ${routeDisabled}`}>
                {shop.name.toLowerCase()}
              </div>
              <div className={`bajada-tienda ${routeDisabled}`}>
                {categories.map((item, index) => (
                  <span key={item.name}>
                    {item.name} {index < categories.length - 1 ? ' - ' : null}
                  </span>
                ))}
              </div>
            </span>
            <span>{this.renderShopState(shop)}</span>
          </li>
        );
      }

      return null;
    });

  renderShopState = shop => {
    let stateClass = '';
    let text = shop.poiStateId && this.textPoi[shop.poiStateId];
    const start = shop.openingTime || DEFAULT_OPENING;
    const end = shop.closingTime || DEFAULT_CLOSING;
    let showHours = false;

    if (shop.poiStateId === STATE_ACTIVE) {
      showHours = true;
      stateClass = `active ${handleOpen(start, end, true, false)}`;
      text = <Trans i18nKey={`${handleOpen(start, end, true, true)}`} />;
    } else {
      stateClass = 'fill-red';
    }

    return (
      <div className='information-container'>
        <div className={`${stateClass} informacion-adicional`}>
          <div className='status-information'>
            {text}&nbsp;
            {showHours && (
              <span className='text-hours'>
                {`${moment(start, timeFormat.format2).format(
                  timeFormat.format2
                )} A ${moment(end, timeFormat.format2).format(
                  timeFormat.format2
                )}`}
              </span>
            )}
          </div>
        </div>
        <div className='label label-piso'>Piso {shop.floor}</div>
      </div>
    );
  };

  render() {
    const {
      data,
      onStoreClick,
      underLine,
      postUnderLine,
      showNoResults,
      lastSearchInput,
      partialMatch,
      directory,
      suggestedTags,
      suggestedTagsProducts
    } = this.props;

    const urlStore = `/${getTotemData(true).deviceName}/MainTienda`;
    const urlService = `/${getTotemData(true).deviceName}/MainServicios`;

    let title = 'smart_search_results_title';
    const partialMatchTitle = 'smart_search_result_title_partial';

    if (directory === DIRECTORY.GASTRONOMY) {
      title = 'smart_search_gastronomy_results_title';
    }

    if (data.length === 1) {
      title = 'smart_search_result_title';

      if (directory === DIRECTORY.GASTRONOMY) {
        title = 'smart_search_gastronomy_result_title';
      }
    }

    if (underLine) {
      title = underLine;
    }

    let textNoResults = (
      <React.Fragment>
        <Trans i18nKey='smart_search_no_store_results_first' />
        {` "${lastSearchInput}" `}
        <Trans i18nKey='smart_search_no_store_results_second' />
      </React.Fragment>
    );

    if (directory === DIRECTORY.GASTRONOMY) {
      textNoResults = (
        <React.Fragment>
          <Trans i18nKey='smart_search_no_gastronomy_results_first' />
          {` "${lastSearchInput}" `}
          <Trans i18nKey='smart_search_no_gastronomy_results_second' />
          <div className='text-link'>
            <Trans
              i18nKey='smart_search_no_gastronomy_results_third'
              components={[<Link to={urlStore} />, <Link to={urlService} />]}
            />
          </div>
        </React.Fragment>
      );
    } else if (directory === DIRECTORY.STORE) {
      textNoResults = (
        <React.Fragment>
          <Trans i18nKey='smart_search_no_store_results_first' />
          {` "${lastSearchInput}" `}
          <Trans i18nKey='smart_search_no_store_results_second' />
          <div className='text-link'>
            <Trans
              i18nKey='smart_search_no_store_results_third'
              components={[<Link to={urlService} />]}
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h4>
          <Trans i18nKey={!partialMatch ? title : partialMatchTitle} />{' '}
          {postUnderLine ? <strong>{`"${lastSearchInput}"`}</strong> : ''}
        </h4>
        {data && data.length ? (
          <ul className='lista-tiendas'>
            {this.renderShops(data, onStoreClick)}
          </ul>
        ) : (
          <React.Fragment>
            {showNoResults && (
              <React.Fragment>
                {suggestedTags.length > 0 ||
                suggestedTagsProducts.length > 0 ? (
                  <React.Fragment>
                    <div className='no-results no-result-container'>
                      {textNoResults}
                      <div className='dialog-tag-container'>
                        <div className='detail-dialog' />
                        <div className='dialog-tag-list'>
                          <div className='title-tag'>
                            {window.location.pathname !== '/MainGastronomia' ? (
                              <React.Fragment>
                                {suggestedTags && suggestedTags.length > 0 ? (
                                  'Pero te podría interesar también: '
                                ) : (
                                  <React.Fragment>
                                    Pero puedes encontrar productos
                                    <span className='blue-tag'>
                                      {' '}
                                      {lastSearchInput}{' '}
                                    </span>{' '}
                                    en:
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                Pero te podría interesar también:
                              </React.Fragment>
                            )}

                            <ul className='lista-tiendas lista-tiendas-tags'>
                              {(suggestedTags && suggestedTags.length) > 0
                                ? this.renderShops(suggestedTags, onStoreClick)
                                : this.renderShops(
                                    suggestedTagsProducts,
                                    onStoreClick
                                  )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className='no-results'>{textNoResults}</div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default ListaTiendas;
