import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const isDebug = (process.env.REACT_APP_I18N_DEBUG || '').trim() === '';

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translations: {
        smart_search_store: 'TIENDA',
        smart_search_stores: 'TIENDAS',
        smart_search_categories: 'CATEGORÍAS',
        smart_search_services: 'UBICACIONES',
        smart_search_service: 'UBICACIÓN',

        smart_search_related_stores_result:
          'Coincidencia en <strong>tiendas relacionadas </strong>',
        smart_search_related_stores_results:
          'Coincidencias en <strong>tiendas relacionadas </strong>',

        smart_search_stores_that_sell_result:
          'Coincidencia en <strong>tiendas que venden</strong>',

        smart_search_stores_that_sell_results:
          'Coincidencias en <strong>tiendas que venden</strong>',

        smart_search_closes_soon: 'Cierra pronto',
        smart_search_open: 'Abierto Ahora',
        smart_search_closed: 'Cerrado temporalmente',
        smart_search_closed_for_today: 'Cerrado por hoy',
        smart_search_open_soon: 'Abre pronto',

        smart_search_result_title: 'Coincidencia en <strong>Tiendas</strong>',
        smart_search_results_title: 'Coincidencia en <strong>Tiendas</strong>',
        smart_search_result_title_partial: 'o quizás estás buscando:',

        smart_search_gastronomy_result_title:
          'Coincidencia en <strong>restaurantes</strong>',
        smart_search_gastronomy_results_title:
          'Coincidencias en <strong>restaurantes</strong>',

        smart_search_category_result_title:
          'Coincidencia en <strong>categoría</strong>',
        smart_search_category_results_title:
          'Coincidencias en <strong>categoría</strong>',

        smart_search_service_result_title:
          'Coincidencia en <strong>servicios</strong>',
        smart_search_services_results_title:
          'Coincidencias en <strong>servicios</strong>',

        smart_search_no_store_results_first: 'No se ha encontrado la tienda',
        smart_search_no_store_results_second: 'en Parque Arauco.',
        smart_search_no_store_results_third:
          '¿Quizás estás buscando un <0>Servicio</0>?',

        smart_search_no_service_results_first:
          'No se ha encontrado el servicio',
        smart_search_no_service_results_second: 'en Parque Arauco.',
        smart_search_no_service_results_third:
          '¿Quizás estás buscando una <0>Tienda</0>?',

        smart_search_no_gastronomy_results_first:
          'No se ha encontrado el restaurante',
        smart_search_no_gastronomy_results_second: 'en Parque Arauco.',
        smart_search_no_gastronomy_results_third:
          '¿Quizás estás buscando una <0>Tienda</0> o un <1>Servicio</1>?',

        smart_search_no_restaurant_results_first: 'El restaurante',

        smart_search_no_category_results: 'No hay categorías llamadas',
        smart_search_no_service_results: 'No hay servicios llamados',

        smart_search_no_category_results_first:
          'No se ha encontrado la categoría',
        smart_search_no_category_results_second: 'en Parque Arauco.',

        modal_inactividad_titulo_modal: '¿Quieres hacer otra búsqueda?',
        modal_inactividad_question_modal: '¿Quieres extender la sesión?',
        modal_inactividad_time_left_modal: 'La búsqueda se reiniciará en ',
        modal_inactividad_seconds: 'segundos',
        modal_inactividad_second: 'segundo',
        modal_inactividad_boton_si: 'Si, quiero hacer otra búsqueda',
        modal_inactividad_boton_no: 'No, quiero seguir aquí',

        label_abierto_ahora: 'Abierto ahora',
        label_cierra_pronto: 'Cierra pronto',
        label_cerrado: 'Cerrado temporalmente',
        label_cerrado_por_hoy: 'Cerrado por hoy',
        label_abre_pronto: 'Abre pronto',

        label_main_title: '¿Qué estás buscando?',
        label_main_find_car: 'Encuentra <br /> tu auto',
        label_main_client_service: 'Servicio al cliente',
        label_main_bathroom: 'Baños',
        label_main_cars: 'Coches',
        label_main_exits: 'Salidas',
        label_main_other_services: 'Más servicios',
        label_piso: 'Piso',
        label_en_remodelacion: 'En remodelación',
        label_proximamente: 'Próximamente',

        label_tienda: 'TIENDA',
        label_title_gastronomia: 'GASTRONOMIA',
        label_title_servicios: 'SERVICIO',
        label_tiendas: 'Tiendas',
        label_ver_tiendas: 'Ver tiendas',
        label_gastronomia: 'Gastronomía',
        label_restaurantes: 'Ver restaurantes',
        label_categorias: 'Ver categorías',
        label_tipo_tienda: '¿Qué <strong>tienda</strong> buscas?',
        label_tipo_restaurante: '¿Qué <strong>restaurante</strong> buscas?',
        label_tipo_servicio: '¿Qué <strong>servicio</strong> buscas?',
        label_tipo_categoria: '¿Qué <strong>categoría</strong> buscas?',
        label_ticket: '¿Qué pasa si pierdo mi ticket?',
        label_cajero: 'Ruta pago cajero',

        busqueda_realizar_otra: 'Realiza otra búsqueda',
        busqueda_patente_header:
          'Ingresa tu <strong>patente</strong> para <br />encontrar tu <strong>auto</strong>',
        busqueda_patente_header_tablet:
          'Ingresa tu <strong>patente</strong> para encontrar tu <strong>auto</strong>',
        busqueda_patente_no_recuerdas: '¿No recuerdas tu patente?',
        busqueda_patente_no_recuerdas_descripcion:
          'Busca el número de tu patente en <br /> el ticket de estacionamiento.',
        busqueda_patente_no_recuerdas_descripcion_tablet:
          'Busca el número de tu patente en el ticket de estacionamiento.',
        busqueda_patente_footer:
          'O puedes buscar directamente el estacionamiento',
        busqueda_patente_busqueda_button: 'No recuerdo mi patente',
        busqueda_patente_busqueda_button_upps: 'Buscar por estacionamiento',
        busqueda_patente_resultado_titulo_modal: 'IMPORTANTE',
        busqueda_patente_resultado_cuerpo_modal:
          'Las fotografías de los autos son sacadas al momento de estacionarse.',
        busqueda_patente_resultado_boton_modal: 'Entendido',
        busqueda_patente_resultado_boton_ver: 'Ver',
        busqueda_patente_resultado_txt_auxiliar:
          'Selecciona tu auto de las siguientes opciones',
        busqueda_patente_resultado_txt_auxiliar_short:
          '¡Hemos encontrado tu auto!',
        busqueda_patente_btn_inicio: 'Inicio',
        busqueda_estacionamiento_btn_volver: 'Volver a búsqueda por patente',
        busqueda_estacionamiento_header:
          'Utiliza el <strong>nivel</strong> <br/> para encontrar tu <strong>estacionamiento</strong>',
        busqueda_estacionamiento_header_mobile:
          'Utiliza el <strong>nivel</strong> para encontrar tu <strong>estacionamiento</strong>',
        busqueda_patente_btn_ver_pisos: 'Ver pisos de estacionamiento',
        busqueda_patente_btn_no_es_ninguno: 'No es ninguno de estos autos',
        busqueda_patente_titulo_footer: '¿No es ninguno de estos?',
        busqueda_patente_cuerpo_footer:
          'En ese caso te recomendamos ir directo al <strong>piso de estacionamiento</strong>',
        busqueda_patente_msj_titulo_not_found: 'Ups!!',
        busqueda_patente_msj_cuerpo_not_found:
          'Tenemos un problema para reconocer tu patente en este momento…',
        busqueda_patente_msj_titulo_error: 'Ups!!',
        busqueda_patente_msj_cuerpo_error:
          'Algo se desconectó y no podemos buscar tu auto de forma automática…',
        busqueda_patente_buscar_por_estacionamiento:
          'Te recomendamos utilizar la búsqueda por estacionamiento',

        busqueda_patente_encontrado_volver: 'Volver',
        busqueda_patente_encontrado_titulo: '¡Lo hemos encontrado!',
        busqueda_patente_encontrado_patente: 'Tu auto patente:',
        busqueda_patente_encontrado_se_encuentra: 'Se encuentra en:',
        busqueda_patente_encontrado_titulo_estacionamiento: 'ESTACIONAMIENTO',
        busqueda_patente_encontrado_boton_llevar: 'Llévame a mi auto',
        busqueda_patente_encontrado_btn_como_llegar: '¡Veamos cómo llegar!',
        busqueda_patente_autoMultiples: '¿No encontramos tu auto?',
        busqueda_patente_search_button: 'Buscar por piso',

        busqueda_patente_encontrado_color_BLANCO: 'BLANCO',
        busqueda_patente_encontrado_color_AZUL: 'AZUL',
        busqueda_patente_encontrado_color_ROSADO: 'ROSADO',
        busqueda_patente_encontrado_color_CAFE: 'CAFE',
        busqueda_patente_encontrado_color_ROJO: 'ROJO',
        busqueda_patente_encontrado_color_AMARILLO: 'AMARILLO',
        busqueda_patente_encontrado_color_NARANJO: 'NARANJO',
        busqueda_patente_encontrado_color_VERDE: 'VERDE',
        busqueda_patente_encontrado_color_CELESTE: 'CELESTE',
        busqueda_patente_encontrado_color_DORADO: 'DORADO',
        busqueda_patente_encontrado_color_MORADO: 'MORADO',

        busqueda_estacionamiento_resultado_btn_volver: 'Volver',
        busqueda_estacionamiento_zona_paris_ripley: 'ZONA PARIS-RIPLEY',
        busqueda_estacionamiento_zona_blvd_falabella: 'ZONA BLVD-FALABELLA',
        busqueda_patente_tooltip_lupa: 'Haz click para buscar la patente',

        label_intermedia_estas_viendo: 'Estás viendo la categoría',
        label_intermedia_tienda_singular: 'Tienda',
        label_intermedia_tienda_plural: 'Tiendas',
        label_intermedia_servicio_singular: 'Servicio',
        label_intermedia_servicio_plural: 'Servicios',
        label_intermedia_volver_gastronomia: 'Volver a gastronomía',
        label_intermedia_volver_tiendas: 'Volver a tiendas',
        label_intermedia_volver_servicios: 'Volver a servicios',

        busqueda_sin_resultados:
          'Algo se desconectó y no podemos realizar la búsqueda.',
        busqueda_sin_resultados_tienda:
          'Algo se desconectó y no podemos mostrar la lista de tiendas...',
        busqueda_sin_resultados_restaurant:
          'Algo se desconectó y no podemos mostrar la lista de restaurantes...',
        busqueda_sin_resultados_categoria:
          'Algo se desconectó y no podemos mostrar la lista de categorías...',
        busqueda_sin_resultados_servicio:
          'Algo se desconectó y no podemos mostrar la lista de servicios...',
        map_repeat_route: 'Repetir ruta',
        map_loading: 'Cargando...',
        map_level_1: 'Piso 1',
        map_level_2: 'Piso 2',
        map_level_3: 'Piso 3',
        text_up_escalator: 'Sube las escaleras mecánicas',
        text_low_escalator: 'Baja las escaleras mecánicas',
        text_up_stairs: 'Sube las escaleras',
        text_low_stairs: 'Baja las escaleras',
        text_up_lift: 'Sube en el ascensor',
        text_low_lift: 'Baja en el ascensor'
      }
    },
    en: {
      translations: {
        smart_search_store: 'STORE',
        smart_search_stores: 'STORES',
        smart_search_categories: 'CATEGORIES',
        smart_search_services: 'SERVICES',

        smart_search_result_title: '<strong>STORE</strong> MATCH',
        smart_search_results_title: '<strong>STORE</strong> MATCHES',
        smart_search_result_title_partial: 'Maybe you are looking for:',

        smart_search_related_stores_result:
          'MATCH IN <strong>RELATED STORES</strong>',
        smart_search_related_stores_results:
          'MATCHES IN <strong>RELATED STORES</strong>',

        smart_search_stores_that_sell_result:
          'MATCH IN <strong>STORES THAT SELL</strong>',

        smart_search_stores_that_sell_results:
          'MATCHES IN <strong>STORES THAT SELL</strong>',

        smart_search_category_result_title:
          'MATCH IN <strong>CATEGORIES</strong>',
        smart_search_category_results_title:
          'MATCHES IN <strong>CATEGORIES</strong>',

        smart_search_closes_soon: 'Closes soon',
        smart_search_open: 'Now Open',
        smart_search_closed: 'Temporarily closed',
        smart_search_closed_for_today: 'Closed for today',
        smart_search_open_soon: 'Open soon',

        smart_search_no_store_results_first: 'The store',
        smart_search_no_store_results_second: 'not found in Parque Arauco.',
        smart_search_no_store_results_third:
          'Maybe you are looking for a <0>Service</0>?',

        smart_search_no_service_results_first: 'The service',
        smart_search_no_service_results_second: 'not found in Parque Arauco.',
        smart_search_no_service_results_third:
          'Maybe you are looking for a <0>Store</0>?',

        smart_search_no_gastronomy_results_first: 'The restaurant',
        smart_search_no_gastronomy_results_second:
          'not found in Parque Arauco.',
        smart_search_no_gastronomy_results_third:
          'Maybe you are looking for a <0>Store</0> or a <1>Service</1>?',

        smart_search_no_restaurant_results_first: 'The restaurant',

        smart_search_no_category_results: 'No Categories called',
        smart_search_no_service_results: 'There are no services called',

        smart_search_no_category_results_first: 'The category',
        smart_search_no_category_results_second: 'not found in Parque Arauco.',

        modal_inactividad_titulo_modal: 'Do you want to do another search?',
        modal_inactividad_question_modal: 'Do you want to extend your session?',
        modal_inactividad_time_left_modal: 'The search will restart in ',
        modal_inactividad_seconds: 'seconds',
        modal_inactividad_second: 'second',
        modal_inactividad_boton_si: 'Yes, I want to do another search',
        modal_inactividad_boton_no: 'No, I want to stay here',

        label_abierto_ahora: 'Open now',
        label_cierra_pronto: 'Close soon',
        label_cerrado: 'Temporarily closed',
        label_cerrado_por_hoy: 'Closed for today',
        label_abre_pronto: 'Open soon',

        label_main_title: 'What are you looking for?',
        label_main_find_car: 'Find <br /> your car',
        label_main_client_service: 'Customer service',
        label_main_bathroom: 'Bathrooms',
        label_main_cars: 'Cars',
        label_main_other_services: 'Other services',
        label_piso: 'Floor',
        label_en_remodelacion: 'In makeover',
        label_proximamente: 'Soon',

        label_tienda: 'STORE',
        label_title_gastronomia: 'GASTRONOMY',
        label_tiendas: 'Stores',
        label_ver_tiendas: 'Stores',
        label_gastronomia: 'Gastronomy',
        label_restaurantes: 'Restaurants',
        label_categorias: 'Categories',
        label_tipo_tienda: 'What <strong>store</strong> are you looking for?',
        label_tipo_restaurante:
          'What <strong>restaurant</strong> are you looking for?',
        label_tipo_servicio:
          'What <strong>service</strong> are you looking for?',
        label_tipo_categoria:
          'What <strong>category</strong> are you looking for?',
        label_ticket: 'What happens if I lose my ticket?',
        label_cajero: 'Route cashier payment',

        busqueda_realizar_otra: 'Make another search',
        busqueda_patente_header:
          'Enter your <strong>license plate </strong> to <br />find your <strong>car</strong>',
        busqueda_patente_header_tablet:
          'Enter your <strong>license plate </strong> to find your <strong>car</strong>',
        busqueda_patente_no_recuerdas: "Don't remember your license plate?",
        busqueda_patente_no_recuerdas_descripcion:
          'Look for your license plate <br /> on the parking ticket',
        busqueda_patente_no_recuerdas_descripcion_tablet:
          'Look for your license plate on the parking ticket',
        busqueda_patente_footer: 'Or you can search the parking floor directly',
        busqueda_patente_busqueda_button: "I don't remember my license plate",
        busqueda_patente_busqueda_button_upps: 'Search for parking floor',
        busqueda_patente_resultado_titulo_modal: 'IMPORTANT',
        busqueda_patente_resultado_cuerpo_modal:
          'Car photographs are taken at the time of parking.',
        busqueda_patente_resultado_boton_modal: 'Understood',
        busqueda_patente_resultado_boton_ver: 'See',
        busqueda_patente_resultado_txt_auxiliar:
          'Select your car from the following options',
        busqueda_patente_resultado_txt_auxiliar_short: '¡We found your car!',
        busqueda_patente_btn_inicio: 'Home',
        busqueda_estacionamiento_btn_volver: 'Return to license plate search',
        busqueda_estacionamiento_header:
          'Use the <strong>level</strong> <br/> to find your <strong>parking</strong>',
        busqueda_estacionamiento_header_mobile:
          'Use the <strong>level</strong> to find your <strong>parking</strong>',
        busqueda_patente_btn_ver_pisos: 'See parking floors',
        busqueda_patente_btn_no_es_ninguno: "It's none of these cars",
        busqueda_patente_titulo_footer: "It isn't any of these?",
        busqueda_patente_cuerpo_footer:
          'In that case we recommend you go directly to the <strong> parking floor </strong>',
        busqueda_patente_msj_titulo_not_found: 'Oops!!',
        busqueda_patente_msj_cuerpo_not_found:
          'We have a problem recognizing your license plate right now...',
        busqueda_patente_msj_titulo_error: 'Oops!!',
        busqueda_patente_msj_cuerpo_error:
          "Something is offline and we can't search your car automatically...",
        busqueda_patente_buscar_por_estacionamiento:
          'We recommend using the search by parking floor',

        busqueda_patente_encontrado_volver: 'Back',
        busqueda_patente_encontrado_titulo: 'We found it!',
        busqueda_patente_encontrado_patente: 'Your car license:',
        busqueda_patente_encontrado_se_encuentra: 'Is parked at:',
        busqueda_patente_encontrado_titulo_estacionamiento: 'PARKING LOT',
        busqueda_patente_encontrado_boton_llevar: 'Take me to my car',
        busqueda_patente_encontrado_btn_como_llegar:
          "Let's see how to get there!",
        busqueda_patente_autoMultiples: "Can't find your car?",
        busqueda_patente_search_button: 'Search by floor',

        busqueda_patente_encontrado_color_BLANCO: 'WHITE',
        busqueda_patente_encontrado_color_AZUL: 'BLUE',
        busqueda_patente_encontrado_color_ROSADO: 'PINK',
        busqueda_patente_encontrado_color_CAFE: 'BROWN',
        busqueda_patente_encontrado_color_ROJO: 'RED',
        busqueda_patente_encontrado_color_AMARILLO: 'YELLOW',
        busqueda_patente_encontrado_color_NARANJO: 'ORANGE',
        busqueda_patente_encontrado_color_VERDE: 'GREEN',
        busqueda_patente_encontrado_color_CELESTE: 'LIGHT BLUE',
        busqueda_patente_encontrado_color_DORADO: 'GOLDEN',
        busqueda_patente_encontrado_color_MORADO: 'PURPLE',

        busqueda_estacionamiento_resultado_btn_volver: 'Return',
        busqueda_estacionamiento_zona_paris_ripley: 'ZONE PARIS-RIPLEY',
        busqueda_estacionamiento_zona_blvd_falabella: 'ZONE BLVD-FALABELLA',
        busqueda_patente_tooltip_lupa: 'Click to search for the license plate',

        label_intermedia_estas_viendo: 'You are viewing the category',
        label_intermedia_tienda_singular: 'Store',
        label_intermedia_tienda_plural: 'Stores',
        label_intermedia_servicio_singular: 'Services',
        label_intermedia_servicio_plural: 'Service',
        label_intermedia_volver_gastronomia: 'Back to gastronomy',
        label_intermedia_volver_tiendas: 'Go back to stores',
        label_intermedia_volver_servicios: 'Go back to services',

        busqueda_sin_resultados:
          'Something went offline and we cannot perform the search.',
        busqueda_sin_resultados_tienda: `Something went offline and we can't show the store list...`,
        busqueda_sin_resultados_restaurant: `Something went offline and we can't show the list of restaurants...`,
        busqueda_sin_resultados_categoria: `Something went offline and we can't show the list of categories...`,
        busqueda_sin_resultados_servicio: `Something went offline and we can't show the list of services...`,
        map_repeat_route: 'REPEAT ROUTE',
        map_loading: 'Loading...',
        map_level_1: 'Floor 1',
        map_level_2: 'Floor 2',
        map_level_3: 'Floor 3',
        text_up_escalator: 'Go up the escalator',
        text_low_escalator: 'Go down the escalator',
        text_up_stairs: 'Go up thee stairs',
        text_low_stairs: 'Go down the stairs',
        text_up_lift: 'Go up in the elevator',
        text_low_lift: 'Go down in the elevator'
      }
    },
    pt: {
      translations: {
        smart_search_store: 'LOJA',
        smart_search_stores: 'LOJAS',
        smart_search_categories: 'CATEGORIAS',
        smart_search_services: 'SERVIÇOS',

        smart_search_result_title: 'RESULTADO NA <strong>LOJAS</strong>',
        smart_search_results_title: 'RESULTADOS NA <strong>LOJAS</strong>',
        smart_search_result_title_partial: 'Talvez você esteja procurando:',

        smart_search_related_stores_result:
          'RESULTADO EM <strong>LOJA RELACIONADAS</strong>',
        smart_search_related_stores_results:
          'RESULTADOS EM <strong>LOJA RELACIONADAS</strong>',

        smart_search_stores_that_sell_result:
          'RESULTADO EM <strong>LOJA RELACIONADAS QUE VENDEM</strong>',

        smart_search_stores_that_sell_results:
          'RESULTADOS EM <strong>LOJA RELACIONADAS QUE VENDEM</strong>',

        smart_search_category_result_title:
          'RESULTADO EM <strong>CATEGORIAS</strong>',
        smart_search_category_results_title:
          'RESULTADOS EM <strong>CATEGORIAS</strong>',

        smart_search_closes_soon: 'Fecha em breve',
        smart_search_open: 'Aberto Agora',
        smart_search_closed: 'Fechado temporariamente',
        smart_search_closed_for_today: 'Fechada por oje',
        smart_search_open_soon: 'Abre em breve',

        smart_search_no_store_results_first: 'A loja',
        smart_search_no_store_results_second:
          'não encontrada no Parque Arauco.',
        smart_search_no_store_results_third:
          'Talvez você esteja procurando um <0>Serviço</0>?',

        smart_search_no_service_results_first: 'A serviço',
        smart_search_no_service_results_second:
          'não encontrado no Parque Arauco.',
        smart_search_no_service_results_third:
          'Talvez você esteja procurando uma <0>Loja</0>?',

        smart_search_no_gastronomy_results_first: 'A restaurante',
        smart_search_no_gastronomy_results_second:
          'não encontrado no Parque Arauco.',
        smart_search_no_gastronomy_results_third:
          'Talvez você esteja procurando uma <0>Loja</0> ou um <1>Serviço</1>?',

        smart_search_no_restaurant_results_first: 'O restaurante',

        smart_search_no_category_results: 'Nenhuma categoria chamada',
        smart_search_no_service_results: 'Não há serviços chamados',

        smart_search_no_category_results_first: 'A categoria',
        smart_search_no_category_results_second:
          'não encontrada no Parque Arauco.',

        modal_inactividad_titulo_modal: 'Você quer fazer outra pesquisa?',
        modal_inactividad_question_modal: 'Deseja estender a sessão?',
        modal_inactividad_time_left_modal: 'A pesquisa irá reiniciar em ',
        modal_inactividad_seconds: 'segundos',
        modal_inactividad_second: 'segundo',
        modal_inactividad_boton_si: 'Sim, quero fazer outra pesquisa',
        modal_inactividad_boton_no: 'Não, eu quero ficar aqui',

        label_abierto_ahora: 'Aberto agora',
        label_cierra_pronto: 'Fechar em breve',
        label_cerrado: 'Fechado temporariamente',
        label_cerrado_por_hoy: 'Fechada por oje',
        label_abre_pronto: 'Abre em breve',

        label_main_title: 'O que você está buscando?',
        label_main_find_car: 'Encontre <br /> seu carro',
        label_main_client_service: 'Serviço ao cliente',
        label_main_bathroom: 'Casas de banho',
        label_main_cars: 'Carros',
        label_main_other_services: 'Outros serviços',
        label_piso: 'Nível',
        label_en_remodelacion: 'Em remodelação',
        label_proximamente: 'Em breve',

        label_tienda: 'LOJA',
        label_title_gastronomia: 'GASTRONOMIA',
        label_tiendas: 'Lojas',
        label_ver_tiendas: 'Lojas',
        label_gastronomia: 'Gastronomia',
        label_restaurantes: 'Restaurantes',
        label_categorias: 'Categorias',
        label_tipo_tienda: 'Qual <strong>loja</strong> você está procurando?',
        label_tipo_restaurante:
          'Qual <strong>restaurant</strong> você está procurando?',
        label_tipo_servicio:
          'Qual <strong>serviço</strong> você está procurando?',
        label_tipo_categoria:
          'Qual <strong>categoria</strong> você está procurando?',
        label_ticket: 'O que acontece se eu perder meu ingresso?',
        label_cajero: 'Rota paga direto do caixa',

        busqueda_realizar_otra: 'Faça outra pesquisa',
        busqueda_patente_header:
          'Digite sua <strong>placa</strong> para <br />encontrar seu <strong>carro</strong>',
        busqueda_patente_header_tablet:
          'Digite sua <strong>placa</strong> para encontrar seu <strong>carro</strong>',
        busqueda_patente_no_recuerdas: 'Não se lembra da sua placa?',
        busqueda_patente_no_recuerdas_descripcion:
          'Procure sua placa <br /> no ticket de estacionamento',
        busqueda_patente_no_recuerdas_descripcion_tablet:
          'Procure sua placa no ticket de estacionamento',
        busqueda_patente_footer:
          'Ou você pode procurar diretamente pelo no estacionamento',
        busqueda_patente_busqueda_button: 'Não me lembro da minha placa',
        busqueda_patente_busqueda_button_upps:
          'Procurar andar de estacionamento',
        busqueda_patente_resultado_titulo_modal: 'IMPORTANTE',
        busqueda_patente_resultado_cuerpo_modal:
          'As fotos dos carros são tiradas no momento de estacionar',
        busqueda_patente_resultado_boton_modal: 'Entendido',
        busqueda_patente_resultado_boton_ver: 'Ver',
        busqueda_patente_resultado_txt_auxiliar: '¡Hemos encontrado tu auto!',
        busqueda_patente_resultado_txt_auxiliar_short: '¡We found your car!',
        busqueda_patente_btn_inicio: 'Página inicial',
        busqueda_estacionamiento_btn_volver: 'Voltar à pesquisa de placas',
        busqueda_estacionamiento_header:
          'Use o <strong>nível</strong> <br/> para encontrar seu <strong>estacionamento</strong>',
        busqueda_estacionamiento_header_mobile:
          'Use o <strong>nível</strong> para encontrar seu <strong>estacionamento</strong>',
        busqueda_patente_btn_ver_pisos: 'Ver andares de estacionamento',
        busqueda_patente_btn_no_es_ninguno: 'Não é nenhum desses carros',
        busqueda_patente_titulo_footer: 'Não é nenhuma dessas?',
        busqueda_patente_cuerpo_footer:
          'Nesse caso, recomendamos que você vá diretamente para o <strong> estacionamento </strong>',
        busqueda_patente_msj_titulo_not_found: 'Ups!!',
        busqueda_patente_msj_cuerpo_not_found:
          'Temos um problema para reconhecer sua placa agora...',
        busqueda_patente_msj_titulo_error: 'Ups!!',
        busqueda_patente_msj_cuerpo_error:
          'Algo está offline e não podemos procurar seu carro automaticamente...',
        busqueda_patente_buscar_por_estacionamiento:
          'Recomendamos o uso da pesquisa por estacionamento',

        busqueda_patente_encontrado_volver: 'Voltar',
        busqueda_patente_encontrado_titulo: 'Achamos!',
        busqueda_patente_encontrado_patente: 'Seu carro placa:',
        busqueda_patente_encontrado_se_encuentra: 'Está no:',
        busqueda_patente_encontrado_titulo_estacionamiento: 'ESTACIONAMENTO',
        busqueda_patente_encontrado_boton_llevar: 'leve-me para o meu carro',
        busqueda_patente_autoMultiples: 'Não consegue encontrar o seu carro?',
        busqueda_patente_search_button: 'Pesquisar por andar',
        busqueda_patente_encontrado_btn_como_llegar:
          'Vamos ver como chegar lá!',

        busqueda_patente_encontrado_color_BLANCO: 'BRANCO',
        busqueda_patente_encontrado_color_AZUL: 'AZUL',
        busqueda_patente_encontrado_color_ROSADO: 'ROSA',
        busqueda_patente_encontrado_color_CAFE: 'MARROM',
        busqueda_patente_encontrado_color_ROJO: 'VERMELHO',
        busqueda_patente_encontrado_color_AMARILLO: 'AMARELO',
        busqueda_patente_encontrado_color_NARANJO: 'LARANJA',
        busqueda_patente_encontrado_color_VERDE: 'VERDE',
        busqueda_patente_encontrado_color_CELESTE: 'AZUL',
        busqueda_patente_encontrado_color_DORADO: 'DOURADO',
        busqueda_patente_encontrado_color_MORADO: 'ROXO',

        busqueda_estacionamiento_resultado_btn_volver: 'Voltar',
        busqueda_estacionamiento_zona_paris_ripley: 'ZONA PARIS-RIPLEY',
        busqueda_estacionamiento_zona_blvd_falabella: 'ZONA BLVD-FALABELLA',
        busqueda_patente_tooltip_lupa: 'Clique para procurar a placa',

        label_intermedia_estas_viendo: 'Você está vendo a categoria',
        label_intermedia_tienda_singular: 'Loja',
        label_intermedia_tienda_plural: 'Lojas',
        label_intermedia_servicio_singular: 'Serviço',
        label_intermedia_servicio_plural: 'Serviços',
        label_intermedia_volver_gastronomia: 'Voltar à gastronomia',
        label_intermedia_volver_tiendas: 'Voltar às lojas',
        label_intermedia_volver_servicios: 'Voltar às serviços',

        busqueda_sin_resultados:
          'Algo ficou offline e não podemos realizar a pesquisa.',
        busqueda_sin_resultados_tienda:
          'Algo ficou offline e não podemos mostrar a lista de lojas...',
        busqueda_sin_resultados_restaurant: `Algo ficou offline e não podemos mostrar a lista de restaurantes...`,
        busqueda_sin_resultados_categoria: `Algo ficou offline e não podemos mostrar a lista de categorias...`,
        busqueda_sin_resultados_servicio: `Algo ficou offline e não podemos mostrar a lista de serviços...`,
        map_repeat_route: 'REPETIR ROTA',
        map_loading: 'Carregando...',
        map_level_1: 'Nível 1',
        map_level_2: 'Nível 2',
        map_level_3: 'Nível 3',
        text_up_escalator: 'Suba a escada rolante',
        text_low_escalator: 'Desça a escada rolante',
        text_up_stairs: 'Subir as escadas',
        text_low_stairs: 'Desça as escadas',
        text_up_lift: 'Suba no elevador',
        text_low_lift: 'Desça no elevador'
      }
    }
  },
  fallbackLng: 'es',
  debug: isDebug,
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
