import config from '../config/api';

import api from './api';

let source;

export const searchApi = (text, language) => {
  if (source) {
    source.cancel('request cancelada.');
  }

  source = api.CancelToken.source();

  return api
    .get(
      `${config.baseApiUrl}/pois/smartsearch?include=["poisPhunware","poiMallZone"]&limit=1000&channelId=1&q=${text}&poiStateIdIn=[1, 3, 4, 6]`,
      {
        cancelToken: source.token,
        headers: {
          'A-Mall-Id': 1,
          'Accept-Language': language
        }
      }
    )
    .catch(error => {
      if (api.isCancel(error)) {
        // console.error('Request canceled', error.message);
      }
    });
};

export const smartSearchApi = (
  text,
  language,
  channelId,
  limit,
  poiStateIdIn,
  directory
) => {
  if (source) {
    source.cancel('request cancelada.');
  }

  source = api.CancelToken.source();

  let poiStateIdInStr = '[]';

  if (Array.isArray(poiStateIdIn)) {
    poiStateIdInStr = String('[')
      .concat(Array(poiStateIdIn).toString())
      .concat(']');
  }

  let urlEndpoint = `${config.baseApiUrl}/smart-search/search?channelId=${channelId}&poiStateIdIn=${poiStateIdInStr}&text=${text}&limit=${limit}`;

  if (directory && directory.length > 0) {
    urlEndpoint = `${config.baseApiUrl}/smart-search/search?channelId=${channelId}&poiStateIdIn=${poiStateIdInStr}&text=${text}&limit=${limit}&directory=${directory}`;
  }

  return api
    .get(urlEndpoint, {
      cancelToken: source.token,
      headers: {
        'A-Mall-Id': 1,
        'Accept-Language': language
      }
    })
    .catch(error => {
      if (api.isCancel(error)) {
        // console.error('Request canceled', error.message);
      }
    });
};
