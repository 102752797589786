import { createActions } from 'reduxsauce';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

import { getMall, getTotemData } from '../config/kiosk';
import { searchApi, smartSearchApi } from '../services/search';
import { logError } from '../helpers/logger';
import { ACTION, CATEGORY } from '../constants/googleAnalytics';
import { DIRECTORY } from '../constants/directories';
import { POI_TYPE } from '../constants/poiType';

const { Types, Creators } = createActions(
  {
    doSearch: ['text'],
    setLoading: ['loading'],
    setSearchStatus: ['key', 'value'],
    setResults: ['data'],
    resetSearch: null
  },
  {
    prefix: 'MAIN_SEARCH/'
  }
);

const { setResults, resetSearch, setLoading, setSearchStatus } = Creators;

const { SET_RESULTS, RESET_SEARCH, SET_LOADING, SET_SEARCH_STATUS } = Types;

let newSearch;

const doMainSearch = text => async (dispatch, getState) => {
  dispatch(setLoading(true));
  dispatch(setSearchStatus('error', false));

  try {
    const leng = getState().app.data.language;
    const request = await searchApi(text, leng);

    if (request && request.status === 200) {
      const result = request.data;

      let shops = [];
      let categories = [];
      let relatedShops = [];
      let tags = [];
      let services = [];
      const suggestedTags = [];

      if (result) {
        shops = result.customers
          .filter(cust => cust.poiTypeId !== POI_TYPE.SERVICES)
          .slice(0, 5);
        categories = result.categoryCustomers
          .filter(cat => cat.poiTypeId !== POI_TYPE.SERVICES)
          .slice(0, 3);
        services = result.categoryCustomers
          .filter(cat => cat.poiTypeId === POI_TYPE.SERVICES)
          .slice(0, 5);
        tags = result.products.slice(0, 3);
      }

      clearTimeout(newSearch);

      newSearch = setTimeout(() => {
        ReactGA.event({
          category: `(MallId = ${getMall()}) ${
            CATEGORY.HOME_BARRA_DE_BUSQUEDA
          };`,
          action: `${ACTION.SS_PALABRA_BUSCADA};`,
          label: `Input = ${text}; Totem = ${getTotemData(true).deviceName};`
        });
        ReactGA4.event(`${ACTION.SS_PALABRA_BUSCADA}`, {
          category: `(MallId = ${getMall()}) ${
            CATEGORY.HOME_BARRA_DE_BUSQUEDA
          };`,
          label: `Input = ${text}; Totem = ${getTotemData(true).deviceName};`
        });

        if (!shops.length) {
          ReactGA.event({
            category: `(MallId = ${getMall()}) ${
              CATEGORY.HOME_BARRA_DE_BUSQUEDA
            };`,
            action: `${ACTION.SS_SIN_TIENDAS};`,
            label: `Input = ${text}; Totem = ${getTotemData(true).deviceName};`
          });
          ReactGA4.event(`${ACTION.SS_SIN_TIENDAS}`, {
            category: `(MallId = ${getMall()}) ${
              CATEGORY.HOME_BARRA_DE_BUSQUEDA
            };`,
            label: `Input = ${text}; Totem = ${getTotemData(true).deviceName};`
          });
        }

        if (!categories.length) {
          ReactGA.event({
            category: `(MallId = ${getMall()}) ${
              CATEGORY.HOME_BARRA_DE_BUSQUEDA
            };`,
            action: `${ACTION.SS_SIN_CATEGORIAS};`,
            label: `Input = ${text}; Totem = ${getTotemData(true).deviceName};`
          });
          ReactGA4.event(`${ACTION.SS_SIN_CATEGORIAS}`, {
            category: `(MallId = ${getMall()}) ${
              CATEGORY.HOME_BARRA_DE_BUSQUEDA
            };`,
            label: `Input = ${text}; Totem = ${getTotemData(true).deviceName};`
          });
        }
      }, 3000);

      if (!shops.length && !tags.length) {
        relatedShops = result.customerRelated.slice(0, 3);
      }

      dispatch(
        setResults({
          shops,
          categories,
          tags,
          relatedShops,
          fetchResult: true,
          inputText: text,
          services,
          suggestedTags
        })
      );
    }
  } catch (err) {
    dispatch(setSearchStatus('error', true));
    logError('[doMainSearch]', {
      error: err,
      path: 'src/actions/search.js',
      totemInfo: getTotemData(true)
    });
  } finally {
    dispatch(setLoading(false));
  }
};

const doMainSmartSearch = (text, directory, isCategory, limit) => async (
  dispatch,
  getState
) => {
  dispatch(setLoading(true));
  dispatch(setSearchStatus('error', false));

  try {
    const leng = getState().app.data.language;
    const channelId = 1;
    const poiStateIdIn = [1, 3, 4, 6];

    const request = await smartSearchApi(
      text,
      leng,
      channelId,
      limit,
      poiStateIdIn,
      directory
    );

    if (request && request.status === 200) {
      const result = request.data;

      let shops = [];
      let suggestedShops = [];
      let categories = [];
      let suggestedCategories = [];
      const relatedShops = [];
      const tags = [];
      let suggestedTags = [];
      let suggestedTagsProducts = [];
      let services = [];
      let suggestedServices = [];
      const quantity = Math.round(limit / 2);

      if (result) {
        switch (directory) {
          case DIRECTORY.STORE:
            if (isCategory) {
              categories = result.storeCategoriesAutocomplete
                .filter(cat => cat.poiTypeId !== POI_TYPE.SERVICES)
                .slice(0, quantity);
              suggestedCategories = result.storeCategoriesFuzziness
                .filter(cat => cat.poiTypeId !== POI_TYPE.SERVICES)
                .slice(0, Math.floor(quantity / 2));
              suggestedTags = result.tagsStores
                .filter(cat => cat.poiTypeId !== POI_TYPE.SERVICES)
                .slice(0, Math.floor(quantity / 2));
              suggestedTagsProducts = result.tagsProducts
                .filter(cat => cat.poiTypeId !== POI_TYPE.SERVICES)
                .slice(0, Math.floor(quantity / 2));
            } else {
              shops = result.storesAutocomplete
                .filter(store => store.poiTypeId !== POI_TYPE.SERVICES)
                .slice(0, limit);
              suggestedShops = result.storesFuzziness
                .filter(store => store.poiTypeId !== POI_TYPE.SERVICES)
                .slice(0, quantity);
              suggestedTags = result.tagsStores
                .filter(cat => cat.poiTypeId !== POI_TYPE.SERVICES)
                .slice(0, 3);
              suggestedTagsProducts = result.tagsProducts
                .filter(cat => cat.poiTypeId !== POI_TYPE.SERVICES)
                .slice(0, 3);
            }

            break;
          case DIRECTORY.GASTRONOMY:
            if (isCategory) {
              categories = result.gastronomyCategoriesAutocomplete
                .filter(cat => cat.poiTypeId === POI_TYPE.RESTAURANTS)
                .slice(0, quantity);
              suggestedCategories = result.gastronomyCategoriesFuzziness
                .filter(cat => cat.poiTypeId === POI_TYPE.RESTAURANTS)
                .slice(0, Math.floor(quantity / 2));
              suggestedTags = result.tagsStores
                .filter(cat => cat.poiTypeId !== POI_TYPE.RESTAURANTS)
                .slice(0, Math.floor(quantity / 2));
              suggestedTagsProducts = result.tagsProducts
                .filter(cat => cat.poiTypeId !== POI_TYPE.RESTAURANTS)
                .slice(0, Math.floor(quantity / 2));
            } else {
              shops = result.gastronomyAutocomplete
                .filter(store => store.poiTypeId === POI_TYPE.RESTAURANTS)
                .slice(0, limit);
              suggestedShops = result.gastronomyFuzziness
                .filter(store => store.poiTypeId === POI_TYPE.RESTAURANTS)
                .slice(0, quantity);
              suggestedTags = result.tagsStores
                .filter(cat => cat.poiTypeId === POI_TYPE.RESTAURANTS)
                .slice(0, 3);
              suggestedTagsProducts = result.tagsProducts
                .filter(cat => cat.poiTypeId === POI_TYPE.RESTAURANTS)
                .slice(0, 3);
            }

            break;
          case DIRECTORY.SERVICE:
            services = result.serviceCategoriesAutocomplete.slice(0, quantity);
            suggestedServices = result.serviceCategoriesFuzziness.slice(
              0,
              Math.floor(quantity / 2)
            );
            break;
          default:
            shops = result.storesAutocomplete
              .filter(store => store.poiTypeId !== POI_TYPE.SERVICES)
              .slice(0, limit);
            suggestedShops = result.storesFuzziness
              .filter(store => store.poiTypeId !== POI_TYPE.SERVICES)
              .slice(0, quantity);
            categories = result.storeCategoriesAutocomplete
              .filter(cat => cat.poiTypeId !== POI_TYPE.SERVICES)
              .slice(0, quantity);
            suggestedCategories = result.storeCategoriesFuzziness
              .filter(cat => cat.poiTypeId !== POI_TYPE.SERVICES)
              .slice(0, Math.floor(quantity / 2));
            services = result.serviceCategoriesAutocomplete.slice(0, quantity);
            suggestedServices = result.serviceCategoriesFuzziness.slice(
              0,
              Math.floor(quantity / 2)
            );
            suggestedTags = result.tagsStores.slice(0, 3);
            suggestedTagsProducts = result.tagsProducts.slice(0, 3);
        }
      }

      clearTimeout(newSearch);

      newSearch = setTimeout(() => {
        ReactGA.event({
          category: `(MallId = ${getMall()}) ${
            CATEGORY.HOME_BARRA_DE_BUSQUEDA
          };`,
          action: `${ACTION.SS_PALABRA_BUSCADA};`,
          label: `Input = ${text}; Totem = ${getTotemData(true).deviceName};`
        });
        ReactGA4.event(`${ACTION.SS_PALABRA_BUSCADA}`, {
          category: `(MallId = ${getMall()}) ${
            CATEGORY.HOME_BARRA_DE_BUSQUEDA
          };`,
          label: `Input = ${text}; Totem = ${getTotemData(true).deviceName};`
        });

        if (!shops.length) {
          ReactGA.event({
            category: `(MallId = ${getMall()}) ${
              CATEGORY.HOME_BARRA_DE_BUSQUEDA
            };`,
            action: `${ACTION.SS_SIN_TIENDAS};`,
            label: `Input = ${text}; Totem = ${getTotemData(true).deviceName};`
          });
          ReactGA4.event(`${ACTION.SS_SIN_TIENDAS}`, {
            category: `(MallId = ${getMall()}) ${
              CATEGORY.HOME_BARRA_DE_BUSQUEDA
            };`,
            label: `Input = ${text}; Totem = ${getTotemData(true).deviceName};`
          });
        }

        if (!categories.length) {
          ReactGA.event({
            category: `(MallId = ${getMall()}) ${
              CATEGORY.HOME_BARRA_DE_BUSQUEDA
            };`,
            action: `${ACTION.SS_SIN_CATEGORIAS};`,
            label: `Input = ${text}; Totem = ${getTotemData(true).deviceName};`
          });
          ReactGA4.event(`${ACTION.SS_SIN_CATEGORIAS}`, {
            category: `(MallId = ${getMall()}) ${
              CATEGORY.HOME_BARRA_DE_BUSQUEDA
            };`,
            label: `Input = ${text}; Totem = ${getTotemData(true).deviceName};`
          });
        }
      }, 3000);

      dispatch(
        setResults({
          shops,
          suggestedShops,
          categories,
          suggestedCategories,
          suggestedTags,
          suggestedTagsProducts,
          tags,
          relatedShops,
          fetchResult: true,
          inputText: text,
          services,
          suggestedServices
        })
      );
    }
  } catch (err) {
    dispatch(setSearchStatus('error', true));
    logError('[doMainSmartSearch]', {
      error: err,
      path: 'src/actions/search.js',
      totemInfo: getTotemData(true)
    });
  } finally {
    dispatch(setLoading(false));
  }
};

export {
  Types,
  setResults,
  SET_RESULTS,
  doMainSearch,
  doMainSmartSearch,
  resetSearch,
  RESET_SEARCH,
  setLoading,
  SET_LOADING,
  SET_SEARCH_STATUS
};

export default Creators;
