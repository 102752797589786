import React from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Icon,
  InputAdornment,
  CircularProgress
} from '@material-ui/core/';
import Typical from 'react-typical';
import Keyboard from 'react-simple-keyboard';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Trans } from 'react-i18next';

import { resetSearch, doMainSmartSearch } from '../../actions/search';
import { selectPoi } from '../../actions/lists';
import { showHideMap } from '../../actions/map';
import Error from '../Error/Error';
import imagenMano from '../../assets/images/mano_animada.gif';
import { ACTION, CATEGORY } from '../../constants/googleAnalytics';
import { DIRECTORY } from '../../constants/directories';
import { POI_TYPE } from '../../constants/poiType';
import { getMall, getTotemData } from '../../config/kiosk';
import cerrarSvg from '../../assets/svg/search-input-cross.svg';

import ListaTiendas from './ListaTiendas';
import ListaCategorias from './ListaCategorias';
import ListaServicios from './ListaServicios';

import 'react-simple-keyboard/build/css/index.css';
import './InputBuscador.scss';
import './InputBuscadorDirectorio.scss';
import '../../styles/icons.scss';

class InputBuscadorDirectorio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buscadorAbierto: false,
      inputBuscador: '',
      keyboarDisable: 'none',
      layoutName: 'default',
      mostrar: '',
      listTextoAnimado: [],
      textoAnimado: false,
      directorio: '',
      esCategoria: false
    };

    this.timeoutSearch = null;
  }

  static propTypes = {
    directory: PropTypes.string.isRequired,
    isCategory: PropTypes.bool.isRequired,
    mainSearchResult: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    fetching: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;

    dispatch(resetSearch());
  };

  setTextoAnimado = () => {
    const { directory, isCategory } = this.props;
    const velocidad = 1000;

    const textoAnimado = true;
    let listTextoAnimado = [];

    switch (directory) {
      case DIRECTORY.STORE:
        if (isCategory) {
          listTextoAnimado = [
            'Calzado femenino',
            velocidad,
            'Decoración y hogar',
            velocidad,
            'Farmacias',
            velocidad,
            'Juegos',
            velocidad,
            'Piso diseño',
            velocidad
          ];
        } else {
          listTextoAnimado = [
            'Urban Decay',
            velocidad,
            'Amano',
            velocidad,
            'Bamers',
            velocidad,
            'Zara',
            velocidad,
            'Romano',
            velocidad
          ];
        }

        break;
      case DIRECTORY.GASTRONOMY:
        if (isCategory) {
          listTextoAnimado = [
            'Bazar gourmet',
            velocidad,
            'Boulevard',
            velocidad,
            'Food Court',
            velocidad,
            'Patio de comidas',
            velocidad
          ];
        } else {
          listTextoAnimado = [
            'Brunella',
            velocidad,
            'Chilis',
            velocidad,
            'China Wok',
            velocidad,
            'Cinnabon',
            velocidad,
            'Crepas',
            velocidad
          ];
        }

        break;
      case DIRECTORY.SERVICE:
        listTextoAnimado = [
          'Baños',
          velocidad,
          'Cajeros',
          velocidad,
          'Mudadores',
          velocidad,
          'Cargadores',
          velocidad
        ];
        break;
      default:
        listTextoAnimado = [
          'Ejemplo de texto animado',
          velocidad,
          'Cambio de texto',
          velocidad
        ];
        break;
    }

    this.setState({
      textoAnimado,
      listTextoAnimado,
      directorio: directory,
      esCategoria: isCategory
    });
  };

  componentDidMount = () => {
    this.setTextoAnimado();

    setTimeout(() => {
      this.setState({
        mostrar: 'mostrar',
        keyboarDisable: 'none'
      });
    }, 1000);
  };

  componentDidUpdate = () => {
    const { textoAnimado, directorio, esCategoria } = this.state;

    const { directory, isCategory } = this.props;

    if (directorio !== directory || esCategoria !== isCategory) {
      if (textoAnimado) {
        this.setMostrarTextoAnimado(textoAnimado);
      } else {
        this.setTextoAnimado();
      }
    }
  };

  handleChange = event => {
    let value = null;

    if (event.target === undefined) {
      // teclado virtual
      value = event.toUpperCase();
      this.setState({ inputBuscador: value });
    } else {
      // teclado nativo
      const re = /^[A-Za-z0-9\n\s\u00f1\u00d1]*$/g;

      if (event.target.value === '' || re.test(event.target.value)) {
        value = event.target.value;
        this.setState({ inputBuscador: value });
      }
    }

    this.handleSearch(value || '');
  };

  handleKeyPress = button => {
    const { inputBuscador } = this.state;

    if (button === '{enter}') {
      this.handleSearch(inputBuscador);

      return;
    }

    if (button === '{bksp}') {
      if (inputBuscador.length > 0) {
        this.setState({
          inputBuscador: inputBuscador.substring(0, inputBuscador.length - 1)
        });
      }

      return;
    }

    if (inputBuscador.length >= 5 && button === '{enter}') {
      this.handleSearch(inputBuscador);
    }
  };

  updatedWindowSize = () => {
    this.setState({
      keyboarDisable: 'none'
    });
  };

  OcultarAnimacion = () => {
    const { inputBuscador } = this.state;

    if (inputBuscador === '') {
      this.setState({
        buscadorAbierto: true,
        textoAnimado: false
      });
    }
  };

  MostrarAnimacion = () => {
    this.setState({
      buscadorAbierto: false,
      textoAnimado: true
    });
  };

  handleAbrirTeclado = () => {
    const { dispatch } = this.props;
    const { inputBuscador } = this.state;

    if (!inputBuscador) {
      dispatch(resetSearch());
    }

    this.OcultarAnimacion();
  };

  handleBorrarContenidoInput = () => {
    const { dispatch } = this.props;

    dispatch(resetSearch());

    this.keyboard.clearInput('keyboardName');
    this.setState({
      inputBuscador: '',
      buscadorAbierto: true,
      textoAnimado: false
    });
  };

  handleCerrarTeclado = () => {
    const { dispatch } = this.props;

    dispatch(resetSearch());

    this.setState({
      inputBuscador: ''
    });

    this.MostrarAnimacion();
  };

  handleBlur = () => {
    const { inputBuscador } = this.state;

    if (inputBuscador.length === 0) {
      this.MostrarAnimacion();
    }
  };

  handleSearch = text => {
    const { dispatch, directory, isCategory } = this.props;

    if (text.length >= 3) {
      const limit = 5;

      dispatch(doMainSmartSearch(text, directory, isCategory, limit));
    } else {
      dispatch(resetSearch());
      this.setState({
        buscadorAbierto: true
      });
    }
  };

  handleGoToStore = store => {
    const { dispatch, directory } = this.props;
    const clonedItem = JSON.parse(JSON.stringify(store));
    const kioskOrigin = getTotemData(true).deviceName;

    if (directory === DIRECTORY.STORE) {
      clonedItem.mapTitle = 'TIENDA';
      clonedItem.bgColorClass = 'bg-AZUL';
      clonedItem.originPath = `/${kioskOrigin}/MainTienda`;
    } else if (directory === DIRECTORY.GASTRONOMY) {
      clonedItem.mapTitle = 'RESTAURANT';
      clonedItem.bgColorClass = 'bg-ROJO';
      clonedItem.originPath = `/${kioskOrigin}/MainGastronomia`;
    }

    clonedItem.directory = directory;
    dispatch(selectPoi(clonedItem));
    dispatch(showHideMap(true));
    dispatch(push(`/${getTotemData(true).deviceName}/ResultadoMapa`));
  };

  handleStoreClick = store => () => {
    const { inputBuscador } = this.state;

    this.handleGoToStore(store);

    let categoryGA = CATEGORY.DIRECTORIO_TIENDAS_BUSQUEDA;
    const { directory } = this.props;

    if (directory === DIRECTORY.GASTRONOMY) {
      categoryGA = CATEGORY.DIRECTORIO_GASTRONOMIA_BUSQUEDA;
    } else if (directory === DIRECTORY.SERVICE) {
      categoryGA = CATEGORY.DIRECTORIO_SERVICIOS_BUSQUEDA;
    }

    ReactGA.event({
      category: `(MallId = ${getMall()}) ${categoryGA};`,
      action: `${ACTION.SS_CLICK_TIENDA};`,
      label: `Input = ${inputBuscador}; Tienda = ${store.name}; Totem = ${
        getTotemData(true).deviceName
      };`
    });
    ReactGA4.event(`${ACTION.SS_CLICK_TIENDA}`, {
      category: `(MallId = ${getMall()}) ${categoryGA};`,
      label: `Input = ${inputBuscador}; Tienda = ${store.name}; Totem = ${
        getTotemData(true).deviceName
      };`
    });
  };

  handleCategoryClick = category => () => {
    const { dispatch, directory } = this.props;
    const { inputBuscador } = this.state;
    const clonedCategory = JSON.parse(JSON.stringify(category));

    clonedCategory.poiTypeId = Number(category.poiTypeId);
    clonedCategory.name = category.name;

    let origin = '';
    let destino = '';
    const showMapa =
      category.poiTypeId === POI_TYPE.SERVICES && !category.isSelectable;
    const kioskOrigin = getTotemData(true).deviceName;

    if (category.poiTypeId === POI_TYPE.RESTAURANTS) {
      origin = `/${kioskOrigin}/MainGastronomia`;
      destino = `/${kioskOrigin}/IntermediaGastronomia/${category.categoryId}`;
    } else if (
      (category.poiTypeId === POI_TYPE.SERVICES ||
        category.poiTypeId === POI_TYPE.STORES_SERVICES) &&
      directory === DIRECTORY.SERVICE
    ) {
      destino = category.isSelectable
        ? `/${kioskOrigin}/IntermediaServicios/${category.categoryId}`
        : `/${kioskOrigin}/ResultadoMapa`;
      origin = `/${kioskOrigin}/MainServicios`;
      clonedCategory.bgColorClass = 'bg-GRIS';
    } else {
      origin = `/${kioskOrigin}/MainTienda`;
      destino = `/${kioskOrigin}/IntermediaTienda/${category.categoryId}`;
    }

    clonedCategory.originPath = origin;
    clonedCategory.destinPath = destino;

    dispatch(selectPoi(clonedCategory));
    dispatch(showHideMap(showMapa));
    dispatch(push(destino));

    let categoryGA = CATEGORY.DIRECTORIO_TIENDAS_BUSQUEDA;

    if (directory === DIRECTORY.GASTRONOMY) {
      categoryGA = CATEGORY.DIRECTORIO_GASTRONOMIA_BUSQUEDA;
    } else if (directory === DIRECTORY.SERVICE) {
      categoryGA = CATEGORY.DIRECTORIO_SERVICIOS_BUSQUEDA;
    }

    ReactGA.event({
      category: `(MallId = ${getMall()}) ${categoryGA};`,
      action: `${ACTION.SS_CLICK_CATEGORIA};`,
      label: `Input = ${inputBuscador}; Categoría = ${category.name}; 
      Totem = ${kioskOrigin};`
    });
    ReactGA4.event(`${ACTION.SS_CLICK_CATEGORIA}`, {
      category: `(MallId = ${getMall()}) ${categoryGA};`,
      label: `Input = ${inputBuscador}; Categoría = ${category.name}; 
      Totem = ${kioskOrigin};`
    });
  };

  setMostrarTextoAnimado = value => {
    this.setState({
      textoAnimado: !value
    });
  };

  render() {
    const {
      buscadorAbierto,
      inputBuscador,
      keyboarDisable,
      layoutName,
      mostrar,
      textoAnimado,
      listTextoAnimado
    } = this.state;

    const {
      mainSearchResult,
      fetching,
      error,
      directory,
      isCategory
    } = this.props;

    const data = mainSearchResult;
    const typeSearchEngine = buscadorAbierto ? 'open' : '';
    const sizeCircularProgress = 30;
    const nameClassWritting =
      directory === DIRECTORY.SERVICE ? 'escribiendo-servicios' : 'escribiendo';
    const classSearchEngine =
      inputBuscador.length > 2 &&
      (data.categories.length > 0 ||
        data.suggestedCategories.length > 0 ||
        data.shops.length > 0 ||
        data.suggestedShops.length > 0 ||
        data.services.length > 0 ||
        data.suggestedServices.length > 0)
        ? nameClassWritting
        : 'contenedor-buscador-posicionador';

    return (
      <div className='buscador-directorio'>
        <div
          onClickCapture={this.handleCerrarTeclado}
          className={buscadorAbierto ? 'wrap-backdrop-buscador' : ''}
        />
        <div
          className={`contenedor-buscador ${typeSearchEngine} ${classSearchEngine}`}
        >
          <div className='txt-animado'>
            {textoAnimado && (
              <Typical
                steps={listTextoAnimado}
                loop={Infinity}
                wrapper='span'
              />
            )}
          </div>
          <Input
            className='input-buscador'
            name='inputBuscador'
            id='inputBuscador'
            value={inputBuscador}
            autoComplete='off'
            inputProps={{
              maxLength: 50,
              inputMode: keyboarDisable
            }}
            startAdornment={
              <InputAdornment position='start'>
                <Icon>search</Icon>
              </InputAdornment>
            }
            onFocus={this.handleAbrirTeclado}
            onBlur={this.handleBlur}
            endAdornment={
              fetching ? (
                <CircularProgress
                  className='circular-extended'
                  size={sizeCircularProgress}
                />
              ) : (
                <div
                  style={{ visibility: inputBuscador ? 'visible' : 'hidden' }}
                  onClick={this.handleBorrarContenidoInput}
                >
                  <img alt='' src={cerrarSvg} />
                </div>
              )
            }
            onChange={this.handleChange}
            fullWidth
          />
          {error && buscadorAbierto && (
            <Error
              className='primary-search'
              message={<Trans i18nKey='busqueda_sin_resultados' />}
            />
          )}
          {!error && data.fetchResult && buscadorAbierto && (
            <div className='wrap-resultado-buscador'>
              <div className='adorno-linea-separador' />
              <div className='lista-resultados-main-buscador'>
                {(directory === DIRECTORY.STORE ||
                  directory === DIRECTORY.GASTRONOMY) &&
                  !isCategory && (
                    <React.Fragment>
                      <ListaTiendas
                        data={data.shops}
                        onStoreClick={this.handleStoreClick}
                        lastSearchInput={data.inputText}
                        showNoResults
                        directory={directory}
                        suggestedTags={data.suggestedTags}
                        suggestedTagsProducts={data.suggestedTagsProducts}
                      />
                      {data.suggestedShops &&
                        data.suggestedShops.length > 0 && (
                          <ListaTiendas
                            data={data.suggestedShops}
                            onStoreClick={this.handleStoreClick}
                            lastSearchInput={data.inputText}
                            showNoResults
                            partialMatch
                            directory={directory}
                            suggestedTags={data.suggestedTags}
                            suggestedTagsProducts={data.suggestedTagsProducts}
                          />
                        )}
                    </React.Fragment>
                  )}
                {isCategory && (
                  <React.Fragment>
                    <ListaCategorias
                      data={data.categories}
                      onCategoryClick={this.handleCategoryClick}
                      underLine={<Trans i18nKey='smart_search_categories' />}
                      lastSearchInput={data.inputText}
                      directory={directory}
                      suggestedTags={data.suggestedTags}
                      suggestedTagsProducts={data.suggestedTagsProducts}
                    />
                    {data.suggestedCategories &&
                      data.suggestedCategories.length > 0 && (
                        <ListaCategorias
                          data={data.suggestedCategories}
                          onCategoryClick={this.handleCategoryClick}
                          underLine={
                            <Trans i18nKey='smart_search_categories' />
                          }
                          lastSearchInput={data.inputText}
                          partialMatch
                          directory={directory}
                          suggestedTags={data.suggestedTags}
                          suggestedTagsProducts={data.suggestedTagsProducts}
                        />
                      )}
                  </React.Fragment>
                )}
                {directory === DIRECTORY.SERVICE && (
                  <React.Fragment>
                    <ListaServicios
                      data={data.services}
                      onCategoryClick={this.handleCategoryClick}
                      underLine={<Trans i18nKey='smart_search_services' />}
                      lastSearchInput={data.inputText}
                      directory={DIRECTORY.SERVICE}
                      suggestedTags={data.suggestedTags}
                      suggestedTagsProducts={data.suggestedTagsProducts}
                    />
                    {data.suggestedServices &&
                      data.suggestedServices.length > 0 && (
                        <ListaServicios
                          data={data.suggestedServices}
                          onCategoryClick={this.handleCategoryClick}
                          underLine={<Trans i18nKey='smart_search_services' />}
                          lastSearchInput={data.inputText}
                          partialMatch
                          directory={DIRECTORY.SERVICE}
                          suggestedTags={data.suggestedTags}
                          suggestedTagsProducts={data.suggestedTagsProducts}
                        />
                      )}
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className={buscadorAbierto ? 'mano-helper' : `mano-helper ${mostrar}`}
        >
          <img src={imagenMano} alt='mano' />
        </div>
        {buscadorAbierto && (
          <div className='keyboardContainer posicion-backdrop'>
            <Keyboard
              keyboardRef={r => {
                this.keyboard = r;
              }}
              inputName='keyboardName'
              layoutName={layoutName}
              id='InputPatent'
              value={data.inputText}
              maxLength={50}
              onChange={this.handleChange}
              onFocus={() => Keyboard.dismiss()}
              onKeyPress={this.handleKeyPress}
              layout={{
                default: [
                  '1 2 3 4 5 6 7 8 9 0',
                  'Q W E R T Y U I O P',
                  'A S D F G H J K L Ñ',
                  ' Z X C V B N M {bksp}',
                  ' {space} {enter}'
                ]
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch: action => {
    dispatch(action);
  }
});

const mapStateToProps = state => ({
  language: state.app.data.language,
  mainSearchResult: state.search.data.mainSearchResult,
  fetching: state.search.status.fetching,
  error: state.search.status.error
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputBuscadorDirectorio);
